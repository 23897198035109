/* You can add global styles to this file, and also import other style files */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

$main-color: #001489;
// $main-color-bg: #dee3ff;
$main-color-light: #31aeff;
$main-color-dark: #4f66e4;
$main-color-bg: #f0f8ff;
$disabled-color: #616161;
$disabled-color-bg: #e0dfdf;
$danger-color: #f11d1d;
$danger-color-light: #f76767;
$danger-color-dark: #ca1306;
$danger-color-bg: #fef3f3;
$main-text-color: rgb(15 23 42);
$secondary-text-color: rgb(71 85 105);
$border-color: #a8a8a8;

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: var(--font-family);
  background-color: #fafafa;
  color: var(--main-text-color);
}

code {
  background: var(--tui-background-base-alt);
  color: var(--tui-text-secondary);
  vertical-align: middle;
  box-shadow: inset 0 -2px var(--tui-background-neutral-1);
  padding: 0.1rem 0.4rem;
  border-radius: 0.4rem;
}

.primary-text {
  color: var(--tui-text-primary);
}

.secondary-text {
  color: var(--tui-text-secondary);
}

.tertiary-text {
  color: var(--tui-text-tertiary);
}

.action-text {
  color: var(--tui-text-action);
}

.light-action {
  color: var(--tui-text-action-hover);
}

.negative-text {
  color: var(--tui-text-negative);
}

.light-negative-text {
  color: var(--tui-text-negative-hover);
}

.warn-text {
  color: var(--tui-chart-categorical-08);
}

.success-text {
  color: var(--tui-status-positive);
}

.positive-color {
  color: var(--tui-text-positive);
}

.purple-text {
  color: var(--tui-chart-categorical-10);
}

.light-positive-color {
  color: var(--tui-text-positive-hover);
}

.custom-gradient-bg {
  color: #fff;
  background-image: linear-gradient(43deg, #4158d0 0%, #c850c0 46%, #ffcc70 100%) !important;
  font-weight: bold !important;
}

.neutral-bg {
  background-color: var(--tui-background-neutral-1);
}

.neutral-bg2 {
  background-color: var(--tui-background-neutral-2);
}

.pale-bg {
  background-color: var(--tui-status-info-pale);
}

.base-bg {
  background-color: var(--tui-background-base);
}

.light-blue-bg {
  background-color: var(--tui-service-selection-background);
}

.warn-bg {
  background-color: var(--tui-status-warning-pale);
}

.success-bg {
  background-color: var(--tui-status-positive-pale);
}

.pink-bg {
  background-color: var(--tui-status-negative-pale);
}

:root {
  --main-color: #{$main-color};
  --main-color-bg: #{$main-color-bg};
  --main-color-light: #{$main-color-light};
  --disabled-color: #{$disabled-color};
  --disabled-color-bg: #{$disabled-color-bg};
  --danger-color: #{$danger-color};
  --danger-color-light: #{$danger-color-light};
  --danger-color-dark: #{$danger-color-dark};
  --danger-color-bg: #{$danger-color-bg};
  --main-text-color: #{$main-text-color};
  --secondary-text-color: #{$secondary-text-color};
  --border-color: #{$border-color};
}
